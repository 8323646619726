@font-face {
  font-family: 'GothamLight';
  src: local('GothamLight'), url(./fonts/GothamLight.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: center;
  background-color: #4d4d4d;
  font-family: GothamLight;
}
