.App {
  text-align: center;
  vertical-align: center;
  background-color: #4d4d4d;
  height: 100%;
  width: 100%;
  font-family: GothamLight;
}

.App-header {
  background-color: #222;
  height: 25px;
  padding: 20px;
  color: white;
  margin-bottom: 2em;
}

.App-title {
  font-size: 1.5em;
  margin: 0;
}

.App-intro {
  font-size: large;
}

/* CONTAINER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.SampleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
 
.SampleContainer > * {
  padding: 20px;
}
 

/* Header
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.SampleBox {
  margin-top: -120px;
  font-size: x-large;
  color: white;
}

.page-header {
  padding: 20px;
  background-color: white;
  border-bottom: 5px solid #3cb4e5;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 0%;
  width: 100%;
}

.luxLogo {
  margin-left: 25x;
  width: 120px;
  height: 30px;
}

/* Logo
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.luxDevicesLogo {
  width: 400px;
  position: absolute;
  top: 0%;
  left: auto;
  right: auto;
  margin: -410px 0 0 0;
}

.luxUserLogo {
  width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.luxErrorLogo {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.frame{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: -20px;
}

/* Device Id & input box
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-main {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /*Align device id box to the center*/
  position: absolute;
  top: 80%;
  left: 50%;
  margin: -225px 0 0 -225px;
}

.page-main-login {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /*Align device id box to the center*/
  position: absolute;
  top: 20%;
  left: 50%;
  margin: -225px 0 0 -225px;
}

.deviceId {
  color: lightgrey;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 15px;
  text-align: left;
}

.inputId {
  background-color: rgb(233, 227, 227);
  border-radius: 10px;
  border-color: darkgrey;
  font-size: 20px;
  padding: 12px;
  margin-bottom: 35px;
  outline: none;
  width: 375px;
}

.Button {
  background-color:#36B4E5;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  padding: 15px;
  outline: none;
}

.ButtonDisabled{
  background-color: #73D9F2;
}

.LoginBtn {
  background-color: #36B4E5;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  padding: 15px;
  outline: none;
  width: 50%;
}

.Button:disabled {
  background-color: grey;
}

/* Footer
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.page-footer {
  padding: 20px;
  align-items: center;
  background-color: #333333;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  bottom: 0%;
  width: 100%;
  height: 4%;
}

.footerText {
  color: grey;
  font-size: 12px;
}

a:link {
  color: #3cb4e5;
  text-decoration: none;
}

a:visited {
  color: #3cb4e5;
}

/* Misc
–––––––––––––––––––––––––––––––––––––––––––––––––– */

IFrame {
  left: 50%;
  width: 90%;
  height: 77%;
  margin: 100px 0 0 -45%;
}

.modal {
  display: none;
  position:absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
  min-width: 300px;;
  color: white;
  padding: 20px;
  min-height: 100px;
  border-radius: 6px;
  background-color: #333333;
}

.display {
  display: block;
}

.modal-btn {
  width: 100%;
  background-color:#36B4E5;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  padding: 15px;
  outline: none;
}

.exit{
  background-color: #e43d30;
  margin-bottom: 8px;
}

.close {
  background-color: #36B4E5;
}

.modal-header {
  margin-top: -30px;
}

.modal-body {
  font-size: larger;
  font-weight: lighter;
}

@media only screen and (max-width: 600px) {
  .luxDevicesLogo {
    width: 300px;
    position: absolute;
    top: 0%;
    left: 50%;
    right: auto;
    margin: -310px 0 0 -115px;
  }

  .deviceId {
    color: lightgrey;
    font-size: 20px;
    margin-bottom: 10px;
    margin-left: 60px;
    text-align: left;
  }

  .inputId {
    background-color: rgb(233, 227, 227);
    border-radius: 10px;
    border-color: darkgrey;
    font-size: 20px;
    padding: 12px;
    margin-bottom: 35px;
    outline: none;
    margin-left: 60px;
    width: 74%;
  }

  .Button {
    background-color:#36B4E5;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    padding: 15px;
    outline: none;
    margin-left: 60px;
  }

  .luxUserLogo {
    width: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .page-main-login {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  
    /*Align device id box to the center*/
    position: absolute;
    top: 40%;
    left: 57%;
    margin: -225px 0 0 -225px;
  }

  .page-footer {
    align-items: center;
    background-color: #333333;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    bottom: 0%;
    height: 4%;
    margin-left: -20px;
    width: 100%;
  }

  .footerText {
    color: grey;
    font-size: 10px;
  }

}

@media only screen and (min-width: 601px) and (max-width: 800px) {
  .footerText {
    font-size: 12px;
  }

  .page-footer {
    align-items: center;
    background-color: #333333;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    bottom: 0%;
    height: 4%;
    margin-left: -20px;
  }


  .page-main-login {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  
    /*Align device id box to the center*/
    position: absolute;
    top: 30%;
    left: 45%;
    margin: -225px 0 0 -225px;
  }

}
